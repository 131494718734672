import { useApi } from "@/api/useApi"
import { DOCUMENT_DETAILS, CANCEL_PARSE, PARSING_STATUS } from "@/api/endpoints"
import { useStorage } from "@vueuse/core"

const { mutate, mutationAbortController, fetchAbortController } = useApi()

// Use useStorage to create a synchronized storage key
const aiStore = useStorage("ai-state", {
  steps: [
    { id: 1, label: "Preparing Your File" },
    { id: 2, label: "Extracting Text" },
    { id: 3, label: "Identifying Important Data" },
    { id: 4, label: "Ensuring Accuracy" },
    { id: 5, label: "Ready to Go!" },
  ],
  documentData: null,
  process: null,
  processingTime: 0,
  additionalDetailsSent: false,
  additionalFields: null,
})

export default {
  state: aiStore,
  mutations: {
    SET_PROCESS(state, process) {
      if (state.process?.status?.status !== "failed") {
        state.process = process
      }
    },
    SET_DOCUMENT(state, document) {
      state.documentData = document
    },
    SET_ADDITIONAL_DETAILS_SENT(state, status) {
      state.additionalDetailsSent = status
    },
    SET_ADDITIONAL_FIELDS(state, fields) {
      state.additionalFields = fields
    },
    RESET_STATE(state) {
      state.process = null
      state.documentData = null
      state.additionalDetailsSent = false
      state.additionalFields = null
      state.processingTime = 0
    },
    INCREMENT_PROCESSING_TIME(state) {
      state.processingTime+= 5
    },
    RESET_PROCESSING_TIME(state) {
      state.processingTime = 0
    },
    SET_FAILED_PROCESS_STATUS(state) {
      state.process.status.status = 'failed'
    },
  },

  actions: {
    incrementProcessingTime({ state, commit }) {
      if (state.processingTime > 300) {
        commit("SET_FAILED_PROCESS_STATUS")
        return
      }

      commit("INCREMENT_PROCESSING_TIME")
    },

    async sendAdditionDetails({ commit, state }, payload) {
      if (!state.process?.documentId) {
        return {
          success: false,
          error: "No uploaded document found",
        }
      }
      const res = await mutate("put", DOCUMENT_DETAILS(state.process.documentId), payload)
      if (res.success) {
        commit("SET_ADDITIONAL_DETAILS_SENT", true)
      }
      return res
    },
    async getParsingStatus({ commit }, id) {
      const res = await mutate("get", PARSING_STATUS(id))
      commit("SET_PROCESS", res.data?.data)
      return res
    },
    async restartAI({ commit }) {
      commit("RESET_STATE")
    },
    async cancelParsing({ commit }, id) {
      const res = await mutate("put", CANCEL_PARSE(id))
      if (res.success) {
        commit("RESET_STATE")
      }
      return res
    },
    async getAdditionalFields({ commit, state }) {
      if (!state.process?.documentId) {
        return {
          success: false,
          error: "No uploaded document found",
        }
      }
      const res = await mutate("get", DOCUMENT_DETAILS(state.process.documentId))
      if (res.success) {
        commit("SET_ADDITIONAL_FIELDS", res.data?.data)
      }
      return res
    },
    async abortLatestFetch() {
      if (fetchAbortController) {
        fetchAbortController.abort()
      }
    },
    async abortLatestMutation() {
      if (mutationAbortController) {
        mutationAbortController.abort()
      }
    },
  },

  getters: {
    getProcess(state) {
      return state.process
    },
    getDocument(state) {
      return state.documentData
    },
    getSteps(state) {
      return state.steps
    },
    getAdditionalDetailsSent(state) {
      return state.additionalDetailsSent
    },
    getAdditionalFields(state) {
      return state.additionalFields
    },
  },
}
